import { TabContext, TabPanel } from '@mui/lab';
import {
  Box,
  Button,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  Popper,
  Stack,
  Switch,
  useTheme,
} from '@mui/material';
import OptionsFeed from 'components/optionsFeed/OptionsFeed';
import {
  FullscreenBottomDrawerButton,
  Tabs,
  WatchlistMultiSelect,
} from 'components/shared';
import { AppcuesInfoButton } from 'components/shared/AppcuesInfoButton';
import { HIRO_TAB_LABEL_MAPPING, HIRO_TABS, HiroTab } from 'config';
import { useSetSym } from 'hooks';
import { useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import TuneIcon from '@mui/icons-material/Tune';
import {
  bottomDrawerExpandedState,
  hiroActiveWatchlistsIdsState,
  hiroTnsActiveTabState,
  hiroTnsColumnOrderState,
  hiroTnsColumnSizesState,
  hiroTnsColumnsVisibilityState,
  hirotnsNewFilterState,
  hiroTnsFilterPanelOpenState,
  isMobileState,
  sidebarExpandedState,
  hirotnsActiveCustomFilterState,
  hirotnsSavedFiltersState,
  hirotnsFlowLiveState,
  hirotnsColumnSortModel,
  hiroChartTimeRangeState,
  hiroTnsChartTimeInSyncState,
} from 'states';
import { STOCK_SCREENER_FLOW_CONTENT_ID, updateSearch } from 'util/shared';
import { StockScreener } from './HiroTable';
import { FilterConfig, OptionsFeedColumnKey } from 'types/optionsFeed';
import {
  GridFilterAltIcon,
  GridToolbarColumnsButton,
} from '@spotgamma/x-data-grid-premium';
import { useEffect, useState } from 'react';
import useTnsFilters from 'hooks/optionsFeed/useTnsFilters';
import useToast from 'hooks/useToast';
import { SGTooltip } from 'components/core';

const HiroBottomQuadrantTabs = () => {
  const theme = useTheme();
  const { openToast } = useToast();
  const isMobile = useRecoilValue(isMobileState);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeWatchlistIds, setActiveWatchlistIds] = useRecoilState(
    hiroActiveWatchlistsIdsState,
  );
  const [filterPanelOpen, setFilterPanelOpen] = useRecoilState(
    hiroTnsFilterPanelOpenState,
  );
  const hiroTimeRange = useRecoilValue(hiroChartTimeRangeState);
  const [hiroTnsChartTimeInSync, setHiroTnsChartTimeInSync] = useRecoilState(
    hiroTnsChartTimeInSyncState,
  );
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [hiroTnsLiveFlow, setHiroTnsLiveFlow] =
    useRecoilState(hirotnsFlowLiveState);
  const bottomDrawerExpanded = useRecoilValue(bottomDrawerExpandedState);
  const hiroChartExpanded = useRecoilValue(sidebarExpandedState);
  const setSavedFilters = useSetRecoilState(hirotnsSavedFiltersState);

  const { fetchSavedFilters } = useTnsFilters();

  const [panelBtnEl, setPanelBtnEl] = useState<HTMLButtonElement | null>(null);

  const query = searchParams.get('tab') ?? '';
  const tab = HIRO_TABS.has(query) ? query : (HiroTab.optionsFeed as string);

  const { sym } = useSetSym();

  useEffect(() => {
    async function fetchFilters() {
      try {
        const myFilters: FilterConfig[] = await fetchSavedFilters(true); // fetch only noSym:true filters on hiro
        setSavedFilters(myFilters);
      } catch (err: any) {
        console.error(err);
        openToast({
          message: err.message,
          type: 'error',
          duration: 10000,
        });
      }
    }
    fetchFilters();
  }, []);

  const onTnsChartTimeSyncToggle = (_e: any, checked: boolean) => {
    setHiroTnsChartTimeInSync(checked);
  };

  const handleSettingsOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const settingsOpen = Boolean(anchorEl);
  const settingsId = settingsOpen ? 'toolbar-settings' : undefined;

  const dynamicToolbarComponents = new Map<string, JSX.Element>([
    [
      'hiro-tns-filter-panel-btn',
      <Button
        variant="outlined"
        size="small"
        startIcon={<GridFilterAltIcon />}
        sx={{
          textTransform: 'capitalize',
          fontSize: {
            xs: 12,
            md: 14,
          },
          backgroundColor: filterPanelOpen
            ? theme.palette.button.default
            : 'inherit',
          ':hover': {
            backgroundColor: filterPanelOpen
              ? theme.palette.button.hover
              : 'inherit',
          },
        }}
        onClick={() => setFilterPanelOpen((prev) => !prev)}
      >
        Filters
      </Button>,
    ],
    [
      'hiro-tns-flow-pause-btn',
      <Button
        variant="outlined"
        size="small"
        startIcon={
          hiroTnsLiveFlow ? (
            <PauseCircleFilledRoundedIcon />
          ) : (
            <PlayCircleFilledRoundedIcon />
          )
        }
        sx={{
          textTransform: 'capitalize',
          fontSize: {
            xs: 12,
            md: 14,
          },
        }}
        onClick={() => setHiroTnsLiveFlow((prev) => !prev)}
      >
        {hiroTnsLiveFlow ? 'Live Flow' : 'Paused Flow'}
      </Button>,
    ],
    [
      'hiro-tns-chart-sync-toggle',
      <SGTooltip title="Sync the hiro time range zoom with the options feed table so they remain aligned">
        <FormControlLabel
          sx={{
            marginLeft: 0,
            '& .MuiFormControlLabel-label': {
              color: theme.palette.primary.main,
              textAlign: 'center',
              fontSize: { xs: 12, md: 14 },
            },
          }}
          control={
            <Switch
              size={isMobile ? 'small' : 'medium'}
              sx={{
                '& .MuiSwitch-track': {
                  opacity: 0.25,
                  backgroundColor: theme.palette.success.main,
                },
                '&.Mui-checked .MuiSwitch-track': {
                  opacity: 1,
                },
              }}
              color="success"
              checked={hiroTnsChartTimeInSync}
              onChange={onTnsChartTimeSyncToggle}
            />
          }
          label="Sync Time"
          labelPlacement={isMobile ? 'top' : 'start'}
        />
      </SGTooltip>,
    ],
    [
      'hiro-tns-columns-panel-btn',
      <GridToolbarColumnsButton
        ref={setPanelBtnEl}
        slotProps={{
          button: {
            sx: {
              textTransform: 'capitalize',
              fontSize: {
                xs: 12,
                md: 14,
              },
            },
          },
        }}
      />,
    ],
  ]);

  const fixedOptions = [
    ...(isMobile
      ? [
          <Box id="hiro-tns-columns-panel-btn" />,
          <>
            <SGTooltip title="Settings">
              <IconButton
                aria-describedby={settingsId}
                onClick={handleSettingsOpen}
                size="medium"
                color="primary"
              >
                <TuneIcon />
              </IconButton>
            </SGTooltip>
            <Popper
              id={settingsId}
              open={settingsOpen}
              anchorEl={anchorEl}
              placement="bottom-start"
              sx={{
                zIndex: 1000,
              }}
            >
              <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                <Stack
                  sx={{
                    width: '100%',
                    height: 'auto',
                    padding: '16px',
                    textAlign: 'center',
                    backgroundColor: theme.palette.background.paper,
                    boxShadow: theme.palette.shadows.default,
                    borderRadius: theme.spacing(3),
                  }}
                >
                  <Box id="hiro-tns-filter-panel-btn">
                    {dynamicToolbarComponents.get('hiro-tns-filter-panel-btn')}
                  </Box>
                  ,
                  <Box id="hiro-tns-flow-pause-btn">
                    {dynamicToolbarComponents.get('hiro-tns-flow-pause-btn')}
                  </Box>
                  ,
                  <Box id="hiro-tns-chart-sync-toggle">
                    {dynamicToolbarComponents.get('hiro-tns-chart-sync-toggle')}
                  </Box>
                </Stack>
              </ClickAwayListener>
            </Popper>
          </>,
        ]
      : [
          <Box id="hiro-tns-filter-panel-btn" sx={{ marginX: '4px' }}>
            {dynamicToolbarComponents.get('hiro-tns-filter-panel-btn')}
          </Box>,
          <Box id="hiro-tns-flow-pause-btn" sx={{ marginX: '4px' }}>
            {dynamicToolbarComponents.get('hiro-tns-flow-pause-btn')}
          </Box>,
          <Box id="hiro-tns-columns-panel-btn" />,
          <Box id="hiro-tns-chart-sync-toggle" sx={{ marginX: '4px' }}>
            {dynamicToolbarComponents.get('hiro-tns-chart-sync-toggle')}
          </Box>,
        ]),
    <FullscreenBottomDrawerButton />,
    ...(tab === HiroTab.StockScreener
      ? [
          <WatchlistMultiSelect
            activeWatchlistIds={activeWatchlistIds}
            setActiveWatchlistIds={setActiveWatchlistIds}
          />,
        ]
      : []),
    ...(tab === HiroTab.StockScreener
      ? [<AppcuesInfoButton articleKey={STOCK_SCREENER_FLOW_CONTENT_ID} />]
      : []),
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        background: theme.palette.background.paper,
        boxShadow: theme.palette.shadows.paperBoxShadow,
        borderRadius: theme.spacing(3),
        width: 1.0,
      }}
    >
      <TabContext value={tab}>
        <Tabs
          options={HIRO_TAB_LABEL_MAPPING}
          onChange={(_evt, tab: string) => {
            setSearchParams(updateSearch({ tab }));
          }}
          controlProps={{ options: [], fixedOptions }}
          fontSize={isMobile ? '12px' : '14px'}
        />
        <TabPanel
          key={HiroTab.optionsFeed}
          value={HiroTab.optionsFeed as string}
          sx={{ overflowY: 'hidden' }}
        >
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'hidden',
              overflowX: 'auto',
              height: '100%',
              maxHeight: isMobile ? '500px' : '100%',
            }}
          >
            <OptionsFeed
              isHiroView
              tnsFlowLiveState={hirotnsFlowLiveState}
              columnSortModel={hirotnsColumnSortModel}
              activeCustomFilterState={hirotnsActiveCustomFilterState}
              filterActiveTabState={hiroTnsActiveTabState}
              disabledColumnFilters={[
                OptionsFeedColumnKey.Underlying,
                OptionsFeedColumnKey.Time,
              ]}
              filterPanelProps={{
                openState: hiroTnsFilterPanelOpenState,
                noSym: true,
                panelView: bottomDrawerExpanded ? 'default' : 'modal',
                currentSym: sym,
                hiroTimeRange,
                hiroTnsChartTimeInSync,
              }}
              newFilterItemsState={hirotnsNewFilterState}
              savedFiltersState={hirotnsSavedFiltersState}
              columnVisibilityState={hiroTnsColumnsVisibilityState}
              columnOrderState={hiroTnsColumnOrderState}
              columnSizingState={hiroTnsColumnSizesState}
              activeWatchlistIdsState={hiroActiveWatchlistsIdsState}
              customGridSlotProps={{
                panel: {
                  anchorEl: panelBtnEl,
                  placement: isMobile
                    ? 'bottom-end'
                    : !hiroChartExpanded && !bottomDrawerExpanded
                    ? 'left-end'
                    : bottomDrawerExpanded
                    ? 'bottom-end'
                    : 'top-end',
                },
                toolbar: {
                  filterPanelOpenState: hiroTnsFilterPanelOpenState,
                  flowLiveState: hirotnsFlowLiveState,
                  disableToolbar: true,
                  customPositionedControllers: [
                    {
                      elementId: 'hiro-tns-columns-panel-btn',
                      component: dynamicToolbarComponents.get(
                        'hiro-tns-columns-panel-btn',
                      ),
                    },
                  ],
                },
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel
          key={HiroTab.StockScreener}
          value={HiroTab.StockScreener as string}
          sx={{ overflow: 'hidden' }}
        >
          <Box
            sx={{
              transition: '0.5s',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              height: '100%',
            }}
          >
            <StockScreener />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default HiroBottomQuadrantTabs;
