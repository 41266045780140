import { AccessProtectedPage } from './AccessProtectedPage';
import { ProductType } from 'types';
import { useCallback } from 'react';
import { Trace } from './components/Trace';
import { isValidTraceTimeframe } from 'util/user';

export const TracePage = () => {
  return (
    <AccessProtectedPage
      productType={ProductType.TRACE}
      overrideCheck={isValidTraceTimeframe()} // don't bother with access checks if we're in the valid open access timeframe
      renderContent={useCallback(
        () => (
          <Trace productType={ProductType.TRACE} />
        ),
        [],
      )}
    />
  );
};
