import {
  HEATMAP_TRACE_NAME,
  HIRO_TRACE_NAME,
  TraceFiltersAxisLabels,
  PRICE_CANDLES_TRACE_NAME,
} from '../../config';
import {
  formatAsCompactNumber,
  getDateFormatted,
  getStatsPercentile,
  getTzOffsetMs,
  hexToRGBA,
  predicateSearch,
  roundToNearest,
  strikeBarTraceNameYOffset,
} from '../../util';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import {
  TraceLense,
  TraceStrikeBarType,
  PriceCandle,
  StrikeBarsDailyTracker,
  StrikeBarsDailyTrackerEntry,
  StrikeBarsData,
} from '../../types';
import { dayjs } from '../../util/shared/date';
import { ReactNode, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import {
  oiIntradayInvertedState,
  oiIntradayParquetKeys,
  oiPriceCandleDurationState,
  oiShowGexZeroDteState,
  oiStatsLookbackDaysState,
  oiStrikeBarsTrackerEnabledState,
  oiStrikeBarTypeState,
  timezoneState,
} from '../../states';
import { HeatmapColorSettings, TraceColorSettings } from '../../theme';

type useTooltipProps = {
  intradayDate: dayjs.Dayjs;
  intradaySym: string;
  selectedLense: TraceLense;
  statsDataMap: Map<string, any>;
  strikeBarsData: StrikeBarsData | undefined;
  strikeBarsTracker: StrikeBarsDailyTracker | null;
  heatmapColorSettings: HeatmapColorSettings;
  hiroSym: string;
  getCandlesArr: () => any;
  getHiroFiltered: () => any;
  minY: number | undefined;
  maxY: number | undefined;
  minX: number | undefined;
  maxX: number | undefined;
};

export const useTooltip = ({
  selectedLense,
  intradayDate,
  intradaySym,
  statsDataMap,
  strikeBarsData,
  strikeBarsTracker,
  heatmapColorSettings,
  hiroSym,
  getHiroFiltered,
  getCandlesArr,
  minY,
  maxY,
  minX,
  maxX,
}: useTooltipProps) => {
  const theme = useTheme();

  const parquetKeys = useRecoilValue(oiIntradayParquetKeys);
  const invert = useRecoilValue(oiIntradayInvertedState);
  const tz = useRecoilValue(timezoneState);
  const strikeBarType = useRecoilValue(oiStrikeBarTypeState);
  const candleDuration = useRecoilValue(oiPriceCandleDurationState);
  const showGexZeroDte = useRecoilValue(oiShowGexZeroDteState);
  const statsLookbackDays = useRecoilValue(oiStatsLookbackDaysState);
  const trackerEnabled = useRecoilValue(oiStrikeBarsTrackerEnabledState);

  const [hoverInfo, setHoverInfo] = useState<ReactNode>();
  const [lastHoverXY, setLastHoverXY] = useState<number[] | undefined>();

  const strikeBarTooltipValue = (val: number | undefined) => {
    if (val == null || isNaN(val)) {
      return 'N/A';
    }

    return `${
      strikeBarType === TraceStrikeBarType.GAMMA ? '$' : ''
    }${formatAsCompactNumber(val, {
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })}`;
  };

  const crosshairsChartData = useMemo(() => {
    const x = lastHoverXY?.[0];
    const y = lastHoverXY?.[1];

    if (
      hoverInfo == null ||
      x == null ||
      y == null ||
      minY == null ||
      maxY == null ||
      minX == null ||
      maxX == null
    ) {
      return [];
    }

    const common = {
      xaxis: 'x',
      yaxis: 'y',
      mode: 'lines',
      line: {
        dash: 'dot',
        color: heatmapColorSettings.lastCandleColor,
        width: 0.5,
      },
      hoverinfo: 'none',
    };

    const offset = getTzOffsetMs(tz);

    return [
      // vertical
      {
        x: [x + offset, x + offset],
        y: [minY, maxY],
        ...common,
      },
      // horizontal
      {
        x: [minX + offset, maxX + offset],
        y: [y, y],
        ...common,
      },
    ];
  }, [lastHoverXY, minX, maxX, minY, maxY, hoverInfo]);

  const onHover = (e: any) => {
    if ((e.points?.length ?? 0) === 0) {
      return;
    }

    const hoveredPoint = e.points[0];
    const isStrikeBar = ![
      HEATMAP_TRACE_NAME,
      PRICE_CANDLES_TRACE_NAME,
      HIRO_TRACE_NAME,
    ].includes(hoveredPoint.fullData.name);
    const statsData = statsDataMap.get(getDateFormatted(intradayDate));

    const divider = (
      <Box
        sx={{
          height: '1px',
          width: '100%',
          marginY: '5px',
          background: theme.palette.text.primary,
        }}
      />
    );

    let tooltipBody;

    if (isStrikeBar) {
      const strikeWithOffset = hoveredPoint.y;
      const strike =
        strikeWithOffset -
        strikeBarTraceNameYOffset(hoveredPoint.fullData.name);
      const traceData = strikeBarsData!.chartData.find(
        (d: any) => d.name === hoveredPoint.fullData.name,
      );
      const yIndex = traceData.y.indexOf(strikeWithOffset);
      const strikeBarsVal = traceData.x[yIndex];
      const percentile = getStatsPercentile(
        strikeBarsVal,
        strikeBarType,
        statsData,
        parquetKeys,
        invert,
        isStrikeBar,
        showGexZeroDte,
        hoveredPoint.fullData.name,
        statsLookbackDays,
      );

      let trackerVal;
      if (strikeBarsTracker != null) {
        trackerVal = strikeBarsTracker?.entriesPerTrace
          .get(hoveredPoint.fullData.name)
          ?.get(strike);
      }

      const trackerTooltipEntry = (
        trackerEntry: StrikeBarsDailyTrackerEntry,
        key: keyof StrikeBarsDailyTrackerEntry,
        title: string,
      ) => {
        const val = trackerEntry[key];
        if (val == null) {
          return null;
        }

        const useHoriz = ['dailyMax', 'dailyMin'].includes(key);
        // if tracker is disabled, we still want to show the wicks for dailyMin/dailyMax
        const legend = (trackerEnabled || useHoriz) && (
          <span
            style={{
              height: '2px',
              position: 'relative',
              bottom: '4px',
              width: '10px',
              background: theme.palette.trace.strikeBarSettings.tracker[key],
              display: 'inline-block',
              marginRight: '3px',
              transform: useHoriz ? undefined : 'rotate(90deg)',
            }}
          ></span>
        );

        return (
          <p>
            {legend} {title}: {strikeBarTooltipValue(val)}
          </p>
        );
      };

      tooltipBody = (
        <>
          <Typography>
            Strike: <b>{strike}</b>
          </Typography>
          <Typography>
            {showGexZeroDte ? '0DTE' : ''} {hoveredPoint.fullData.name}:{' '}
            <b>{strikeBarTooltipValue(strikeBarsVal)}</b>
            {percentile != null && (
              <p>
                (<b>{percentile}%</b> {statsLookbackDays}d Percentile)
              </p>
            )}
            {divider}
            {trackerVal != null && (
              <>
                {trackerTooltipEntry(trackerVal, 'last', '10 mins ago')}
                {trackerTooltipEntry(trackerVal, 'thirtyMin', '30 mins ago')}
                {trackerTooltipEntry(trackerVal, 'hour', '60 mins ago')}
                {trackerTooltipEntry(trackerVal, 'dailyMin', 'Daily Minimum')}
                {trackerTooltipEntry(trackerVal, 'dailyMax', 'Daily Maximum')}
              </>
            )}
          </Typography>
        </>
      );

      setLastHoverXY(undefined);
    } else {
      const xTs = roundToNearest(e.xvals[0], candleDuration * 1_000);
      const xDate = dayjs
        .utc(xTs)
        .subtract(dayjs().tz(tz).utcOffset(), 'minutes'); // we get the time to utc, so convert to the tz we want
      const hiroAndPriceCandles = [getCandlesArr(), getHiroFiltered()].map(
        (arr) => {
          const firstIdx = predicateSearch(
            arr,
            (candle: PriceCandle) => candle.time < xDate.valueOf() / 1_000,
          );
          const possibleCandle = arr[firstIdx + 1];
          return possibleCandle?.time === xDate.valueOf() / 1_000
            ? possibleCandle
            : undefined;
        },
      );
      const percentile = getStatsPercentile(
        hoveredPoint.z,
        selectedLense,
        statsData,
        parquetKeys,
        invert,
        isStrikeBar,
        showGexZeroDte,
        hoveredPoint.fullData.name,
        statsLookbackDays,
      );

      tooltipBody = (
        <>
          <Typography>
            Time: <b>{xDate.tz(tz).format('HH:mm')}</b>
          </Typography>
          {divider}
          <Typography>
            Price: <b>{hoveredPoint.y}</b>
          </Typography>
          {hoveredPoint.z != null && (
            <Typography>
              {TraceFiltersAxisLabels.get(selectedLense)}:{' '}
              <b>{formatAsCompactNumber(hoveredPoint.z)}</b>
            </Typography>
          )}
          {percentile != null && (
            <Typography>
              (<b>{percentile}%</b> {statsLookbackDays}d Percentile)
            </Typography>
          )}
          {hiroAndPriceCandles.filter((v) => v != null).length > 0 && divider}
          {hiroAndPriceCandles[0] != null && (
            <Typography>
              {intradaySym} Price at time: <br />
              H: <b>{Math.round(hiroAndPriceCandles[0].high)}</b>
              &nbsp;&nbsp;&nbsp;L:{' '}
              <b>{Math.round(hiroAndPriceCandles[0].low)}</b>
              &nbsp;&nbsp;&nbsp;C:
              <b>{Math.round(hiroAndPriceCandles[0].close)}</b>
            </Typography>
          )}
          {hiroAndPriceCandles[1] != null && (
            <Typography>
              {hiroSym} HIRO at time:{' '}
              <b>{formatAsCompactNumber(hiroAndPriceCandles[1].close)}</b>
            </Typography>
          )}
        </>
      );

      setLastHoverXY([xDate.valueOf(), hoveredPoint.y]);
    }

    const maxWidth = 250;
    let x = e.event.pageX + 5;
    // if the tooltip will render such that we wont have enough room to render the tooltip in its full width
    // then push the x to the left such that it renders the full tooltip in its full width
    x += Math.min(0, e.event.view.innerWidth - x - maxWidth);

    const tooltip = (
      <Box
        sx={{
          position: 'absolute',
          left: x,
          top: e.event.pageY + 5,
          background: theme.palette.background.paper,
          padding: '15px',
          zIndex: 9999,
          pointerEvents: 'none',
        }}
      >
        <Stack direction="column">{tooltipBody}</Stack>
      </Box>
    );

    setHoverInfo(tooltip);
  };

  return { onHover, hoverInfo, setHoverInfo, crosshairsChartData };
};
