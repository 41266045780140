import { useEffect, useState, useRef, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { decode } from '@msgpack/msgpack';
import { userDetailsState } from 'states';
import {
  Filter,
  FilterItem,
  RawOptionFeedData,
  TnsSortedDataWindow,
} from 'types/optionsFeed';
import {
  fitsTnsSortedWindow,
  getOptionFeedDataRow,
  satisfyAllFilters,
} from 'util/optionsFeed';
import { StreamType } from 'types';
import { throttle } from 'lodash';
import { DEFAULT_TNS_SYMBOLS, OF_DEFAULT_FILTER_ID } from 'config/optionsFeed';
import useLog from '../useLog';
import { STREAM_HOST } from 'config/shared';
import { getCachedToken } from 'util/shared';

const MAX_RETRIES = 3;
const RETRY_DELAY = 5000;

const processRow = (
  row: RawOptionFeedData,
  filters: Filter[],
  sortedWindow?: TnsSortedDataWindow,
): boolean => {
  // First check if row passes filters
  if (!satisfyAllFilters(row, filters)) {
    return false;
  }

  // If we have a sort window with all required properties, check if row fits
  if (
    sortedWindow?.sortModel &&
    sortedWindow?.sortModel.length > 0 &&
    sortedWindow.firstRow &&
    sortedWindow.lastRow
  ) {
    return fitsTnsSortedWindow(row, sortedWindow);
  }

  // If no sort window or incomplete window properties, accept the row
  return true;
};

//  WebSocket manager
const WebSocketManager = {
  wsInstance: null as WebSocket | null,
  currentSymbols: ['*'] as string[],
  retryCount: 0,
  retryTimeout: null as number | null,
  subscriber: null as {
    callback: (data: RawOptionFeedData[]) => void;
    filters: Filter[];
    sortedWindow?: TnsSortedDataWindow;
  } | null,

  setSubscriber(
    callback: (data: RawOptionFeedData[]) => void,
    filters: Filter[],
    sortedWindow?: TnsSortedDataWindow,
  ) {
    this.subscriber = { callback, filters, sortedWindow };
  },

  removeSubscriber() {
    this.subscriber = null;
  },

  updateSubscriber(filters: Filter[], sortedWindow?: TnsSortedDataWindow) {
    if (this.subscriber) {
      this.subscriber.filters = filters;
      this.subscriber.sortedWindow = sortedWindow;
    }
  },

  processNewData(newRow: RawOptionFeedData): boolean {
    if (!this.subscriber) {
      return false;
    }

    return processRow(
      newRow,
      this.subscriber.filters,
      this.subscriber.sortedWindow,
    );
  },

  updateSymbols(symbols: string[]) {
    if (!this.wsInstance || this.wsInstance.readyState !== WebSocket.OPEN) {
      return;
    }

    const msg = {
      action: 'subscribe',
      underlyings: symbols,
      stream_types: StreamType.FULL_ABSOLUTE_SIGNAL,
    };
    console.log(`TNS WebSocket subscribing to ${symbols.join(', ')}`);
    this.wsInstance.send(JSON.stringify(msg));
    this.currentSymbols = symbols;
  },

  initialize(token: string, onError: (error: string) => void) {
    // Don't return early if connection exists but is CLOSING or CLOSED
    if (
      this.wsInstance?.readyState === WebSocket.OPEN ||
      this.wsInstance?.readyState === WebSocket.CONNECTING
    ) {
      return;
    }

    // Cleanup any existing connection
    this.cleanup();

    this.wsInstance = new WebSocket(
      `wss://${STREAM_HOST}/stream?token=${encodeURIComponent(token)}`,
    );

    this.wsInstance.onopen = () => {
      console.log('TNS WebSocket connection opened');
      this.retryCount = 0;
      if (this.currentSymbols.length > 0) {
        this.updateSymbols(this.currentSymbols);
      }
    };

    this.wsInstance.onmessage = async (event: MessageEvent) => {
      const { data } = event;
      if (typeof data === 'string') {
        return;
      }

      try {
        const signalTuple: any = decode(await data.arrayBuffer(), {
          useBigInt64: true,
        });
        // Drop the first member member of tuple. It's signal type and we know it.
        const [, signal] = signalTuple;
        const newRow = getOptionFeedDataRow(signal);

        // Only process and emit rows that pass our criteria
        if (this.processNewData(newRow)) {
          if (this.subscriber) {
            this.subscriber.callback([newRow]);
          }
        }
      } catch (error) {
        console.error('Error decoding message:', error);
      }
    };

    this.wsInstance.onclose = () => {
      console.log('TNS WebSocket connection closed');
      if (this.retryCount < MAX_RETRIES) {
        this.retryCount++;
        this.retryTimeout = window.setTimeout(() => {
          console.log(
            `TNS Socket attempting to reconnect... (Attempt ${this.retryCount})`,
          );
          this.initialize(token, onError);
        }, RETRY_DELAY);
      }
    };

    this.wsInstance.onerror = () => {
      onError('An error occurred during the TNS WebSocket connection.');
    };
  },

  cleanup() {
    if (this.wsInstance) {
      this.wsInstance.onclose = null;
      this.wsInstance.onerror = null;
      this.wsInstance.onmessage = null;
      this.wsInstance.onopen = null;
      this.wsInstance.close();
      this.wsInstance = null;
    }
    if (this.retryTimeout) {
      clearTimeout(this.retryTimeout);
      this.retryTimeout = null;
    }
    this.retryCount = 0;
  },
};

const useTnSWebsocket = (
  filters: Filter[] = [],
  setRows: (data: RawOptionFeedData[]) => void,
  sortedDataWindow?: TnsSortedDataWindow,
  isTnsFlowLive = true,
) => {
  const userDetails = useRecoilValue(userDetailsState);
  const [socketError, setSocketError] = useState<string | null>(null);
  const { nonProdDebugLog } = useLog('useTnSWebsocket');
  const bufferedDataRef = useRef<RawOptionFeedData[]>([]);

  // Throttled function to update rows with final filter pass
  const throttledUpdateRows = useRef(
    throttle(() => {
      // Apply filters one last time before setting rows to prevent race conditions
      const finalFilteredData = bufferedDataRef.current.filter((row) =>
        processRow(row, filters, sortedDataWindow),
      );
      setRows(finalFilteredData);
      bufferedDataRef.current = [];
    }, 1_000),
  ).current;

  // Handle new data
  const handleNewData = useCallback(
    (newRows: RawOptionFeedData[]) => {
      bufferedDataRef.current.push(...newRows);
      throttledUpdateRows();
    },
    [throttledUpdateRows],
  );

  // Initialize WebSocket connection
  useEffect(() => {
    if (!userDetails?.isInstitutional && isTnsFlowLive) {
      const token = getCachedToken();
      if (!token) {
        nonProdDebugLog('Invalid or missing streaming token.');
        return;
      }

      // Set up subscriber
      WebSocketManager.setSubscriber(handleNewData, filters, sortedDataWindow);

      // Initialize WebSocket if needed
      WebSocketManager.initialize(token, setSocketError);

      return () => {
        throttledUpdateRows.cancel();
        WebSocketManager.removeSubscriber();
      };
    }

    return () => {
      throttledUpdateRows.cancel();
    };
  }, [userDetails, isTnsFlowLive]);

  // Update subscriber when filters or window changes
  useEffect(() => {
    WebSocketManager.updateSubscriber(filters, sortedDataWindow);
  }, [filters, sortedDataWindow]);

  // Update symbols when filters change
  useEffect(() => {
    const syms =
      ((
        filters.find((f) => f.id === OF_DEFAULT_FILTER_ID.Symbols) as FilterItem
      )?.value as string[]) ?? DEFAULT_TNS_SYMBOLS;
    WebSocketManager.updateSymbols(syms);
  }, [filters]);

  return { error: socketError };
};

export default useTnSWebsocket;
